import {Entity} from "./entity";

export class Role implements Entity {
    isReadable: boolean;
    isEditable: boolean;
    isDeletable: boolean;
    teamsIds: string[];
    teamsNames: any;
    assignedUserId: string;
    assignedUserName: string;
    id: string;
    name: string;
    deleted: boolean;
    assignmentPermission: string;
    assigPermission: string;
    userPermission: string;
    portalPermission: string;
    groupEmailAccountPermission: string;
    exportPermission: string;
    massUpdatePermission: string;
    dataPrivacyPermission: string;
    data: any;
    fieldData: any;
    createdAt: string;
    modifiedAt: string;
}
