import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgApexchartsModule} from 'ng-apexcharts';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

// icons
import {TablerIconsModule} from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

//Import all material modules
import {MaterialModule} from './material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {FilterPipe} from './pipe/filter.pipe';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CommonModule, HashLocationStrategy, LocationStrategy} from "@angular/common";
import {JwtInterceptor} from "./core/helpers/jwt.interceptor";
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {BlockUIModule} from "ng-block-ui";
import {ErrorInterceptor} from "./core/helpers/error.interceptor";
import {ContactUsModule} from "./main/contact-us/contact-us.module";
import {AngularFireModule} from '@angular/fire/compat';
import {environment} from 'src/environments/environment';
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        FilterPipe,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        ToastModule,
        BlockUIModule.forRoot(),
        TablerIconsModule.pick(TablerIcons),
        NgApexchartsModule,
        TablerIconsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ContactUsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),

    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        MessageService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    exports: [TablerIconsModule],
    bootstrap: [AppComponent],
})
export class AppModule {
}
