import { Component } from '@angular/core';

@Component({
    selector: 'block-temp',
    styles: [`
      :host {
        text-align: center;
        color: #1976D2;
      }
    `],
    template: `
        <div style="width: 100vw; height: 100vh; color: white; display: flex; flex-direction:column; align-items: center;justify-content: center;">
            <!-- <img style="animation: spin 1.6s linear infinite; width: 75px;" src="favicon.ico" alt=""> -->
            <div class="spinner"></div>
            <div><span  style="font-size:20px; color:#75b0e4;margin-left: 5%;display:contents;"><strong>{{message}}</strong></span></div>
        </div>
    `
})
export class BlockUiComponent {
    public message : string;
}
