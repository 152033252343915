import {Component, Input, OnInit} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CoreService} from 'src/app/core/services/core.service';
import {BaseService} from "../../../../core/services/base/base.service";
import {UtilsService} from "../../../../core/services/utils/utils.service";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-branding',
    standalone: true,
    imports: [RouterModule, NgIf],
    template: `
        <div class="branding d-none d-lg-flex align-items-center w-100">
            <a [routerLink]="['/']" class="d-flex">
                <img *ngIf="!toggleCollapsed"
                     src="assets/images/logos/maintainv-light.svg"
                     class="align-middle me-3"
                     height="30px"
                     width="auto"
                     alt="logo"
                />
                <img *ngIf="toggleCollapsed"
                     src="assets/images/logos/favicon.svg"
                     class="align-middle me-3"
                     height="30px"
                     width="auto"
                     alt="logo"
                />
            </a>
        </div>
    `,
})
export class BrandingComponent implements OnInit {
    options = this.settings.getOptions();
    toggleCollapsed = false;

    constructor(private settings: CoreService, private utilsService: UtilsService) {
    }

    ngOnInit(): void {
        this.utilsService.onToggleSubscriber().subscribe((res: any) => {
            this.toggleCollapsed = res;
        })
    }


}
