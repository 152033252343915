import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactUsRoutingModule} from './contact-us-routing.module';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from 'src/app/material.module';
import {TablerIconsModule} from 'angular-tabler-icons';

@NgModule({
    declarations: [
        ContactUsComponent
    ],
    imports: [
        CommonModule,
        ContactUsRoutingModule,
        NgScrollbarModule,
        FormsModule,
        MaterialModule,
        TablerIconsModule
    ],
    exports: [ContactUsComponent]
})
export class ContactUsModule {
}
