<div style="position: fixed;
            z-index: 99999999999;
            right: 20px;
            bottom: 20px;">
    <img *ngIf="!openChat"
         (click)="changeVisibility()"
         src="assets/images/chat/chatbot.gif"
         class="chat-bot-image cursor-pointer" alt="chatbot">

    <div class="btn btn-primary"
         style="width: 60px; height: 60px; border-radius: 100%"
         *ngIf="openChat"
         (click)="changeVisibility()">
        <i-tabler style="width: 35px; padding-top: 6px;" name="chevron-down"></i-tabler>
    </div>
</div>

<div class="chatBtn text-end" *ngIf="openChat">
    <!-- Selected Contact -->
    <div class="chat-box" *ngIf="!allContacts">
        <div class="widget-top bg-primary p-2">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center gap-1">
                    <i-tabler (click)="backToAllContact()"
                              class="cursor-pointer"
                              name="chevron-left"
                              style="width: 25px; color: #fff !important;">
                    </i-tabler>
                    <img src="assets/images/chat/chatbot.gif" width="50px" alt="">
                </div>
                <div class="text-white fw-bolder">
                    MaintainV Assistant is with you 24/7 👋
                </div>
            </div>
        </div>
        <div class="widget-body-wrap" >
            <div #scroll class="widget-body p-2"  >
              
                <ul class="list-unstyled mb-0" *ngFor="let data of contactDetail">
                    <!-- User -->
                    <li class="chat-list mt-2 text-end'" *ngIf="data.name">
                        <div class="conversation-list align-items-center justify-content-end gap-2 d-flex">
                            <div>
                                <div class="chat-content">
                                    <div class="user-message">
                                        <p class="mb-0 text-content">{{data.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <!-- Bot -->
                    <li class="chat-list mt-2 text-start" *ngIf="data.questionId">
                        <div class="conversation-list align-items-center justify-content-start gap-2 d-flex">
                            <div>
                                <div class="chat-avatar">
                                    <img src="assets/images/chat/chatbot.png"
                                         width="30px"
                                         alt="">
                                </div>
                            </div>
                            <div>
                                <div class="chat-content">
                                    <div class="bot-message">
                                        <p class="mb-0 text-content">{{data.answer}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="list-unstyled mb-0" *ngIf="isTypingBot">
                    <!-- Typing -->
                    <li class="chat-list mt-2">
                        <div class="conversation-list align-items-center justify-content-start gap-2 d-flex">
                            <div>
                                <div class="chat-avatar">
                                    <img src="assets/images/chat/chatbot.png"
                                         width="30px"
                                         alt="">
                                </div>
                            </div>
                            <div>
                                <div class="chat-content">
                                    <div class="bot-message">
                                        <p class="mb-0">
                                            <i-tabler class="animation-point-1" style="width:15px"
                                                      name="point-filled"></i-tabler>
                                            <i-tabler class="animation-point-2" style="width:15px"
                                                      name="point-filled"></i-tabler>
                                            <i-tabler class="animation-point-3" style="width:15px"
                                                      name="point-filled"></i-tabler>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="widget-footer p-2">
            <div class="d-flex gap-2" *ngIf="selectedContact.status=='active'">
                <input class="form-control contact-input"
                       [disabled]="isTypingBot"
                       (keyup)="onKey($event)"
                       placeholder="{{isTypingBot?'Please waiting...':'Type your message here...'}}"
                       [(ngModel)]="message">
                <button class="btn"
                        [ngClass]="isTypingBot?'btn-outline-secondary':'btn-outline-primary'"
                        [disabled]="isTypingBot"
                        (click)="sendMessage()">
                    <i-tabler name="send"></i-tabler>
                </button>
            </div>
            <div class="d-flex gap-2" *ngIf="selectedContact.status=='passive'">
                <div class="btn w-100 btn-primary" (click)="addNewContact()">
                    New Chat
                </div>
            </div>
        </div>
    </div>

    <!-- All Contacts -->
    <div class="chat-box" *ngIf="allContacts">
        <div class="widget-top bg-primary px-3 p-2">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <img src="assets/images/chat/chatbot.gif" width="50px" alt="">
                </div>
                <div class="text-white fw-bolder">
                    MaintainV Assistant is with you 24/7 👋
                </div>
            </div>
        </div>
        <div class="widget-body-wrap" >
            <div class="widget-body-all p-2">
                <div class="w-100 mb-2 p-2 cursor-pointer"
                     style="background: #f7f7f7;
                            text-align: left;
                            border-radius: 10px;"
                     (click)="selectContact(contact)"
                     *ngFor="let contact of contacts">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-start align-items-center gap-2">
                            <div>
                                <img src="assets/images/chat/chatbot.png" width="30px">
                            </div>
                            <div>
                                <p class="mb-0 text-muted fw-bolder">{{contact.name}}</p>
                                <p class="mb-0 text-muted" *ngIf="contact.status=='active'">Continue chatting</p>
                                <p class="mb-0 text-muted" *ngIf="contact.status=='passive'">Chat ended</p>
                                <p class="mb-0 text-muted">{{contact.dateStart | date :'MMM d, h:mm:ss a'}}</p>
                            </div>
                        </div>
                        <div>
                            <i-tabler style="width: 25px;" name="chevron-right"></i-tabler>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="widget-footer p-2">
            <div class="d-flex gap-2">
                <div class="btn w-100 btn-primary" (click)="addNewContact()">
                    New Chat
                </div>
            </div>
        </div>
    </div>
</div>



