export const environment = {
    api: 'https://app.maintainv.com/api',
    apiUrl: 'https://app.maintainv.com/api/crm',
    crmApiUrl: 'https://app.maintainv.com/main',
    baseApiUrl: 'https://app.maintainv.com/main/vidasmart',
    baseApiUserUrl:'https://app.maintainv.com/regapi',
    production: false,
    app: 'App',
    user: 'user',
    appUser:'User',
    defaultUserName: "register",
    defaultPassword: "DLU8A43nXP",
    forgotPassword: 'passwordChangeRequest',
    forgotPasswordUrl: "https://app.maintainv.com/regapi",
    userSelect: 'accountsIds,accountsNames,apiKey,auth2FA,authMethod,avatarId,avatarName,contactId,contactName,createdAt,createdById,createdByName,dashboardTemplateId,dashboardTemplateName,defaultTeamId,defaultTeamName,deleted,disableDailyEmail,disableEmailNotification,emailAddress,emailAddressData,emailAddressIsOptedOut,firstName,gender,id,isActive,lastAccess,lastName,middleName,modifiedAt,name,phoneNumber,phoneNumberData,phoneNumberIsOptedOut,portalRolesIds,portalRolesNames,portalsIds,portalsNames,rolesIds,rolesNames,salutationName,teamsColumns,teamsIds,teamsNames,title,type,userName,licenseCode,phoneCountry,day,userType,payment',
    workOrderSelect: 'completedDate,assetId,assetName,assignedUserId,assignedUserName,additionalUsersIds,additionalUsersNames,checkListsIds,checkListsNames,createdAt,createdById,createdByName,customersId,customersName,dateEnd,dateEndDate,dateStart,dateStartDate,deleted,description,duration,estimatedDuration,fileIds,fileNames,fileTypes,id,imagesIds,imagesNames,imagesTypes,isAllDay,locationsId,locationsName,modifiedAt,modifiedById,modifiedByName,name,parentId,parentName,parentType,priority,reminders,status,teamsIds,teamsNames,workOrderDetailsIds,workOrderDetailsNames,workOrderNo,workOrderTypeId,workOrderTypeName,requiresSignature,signatureId,signatureName,bookmarked,time,laborTime',
    periodicMaintenanceSelect: "assetsIds,assetsNames,assignedUserId,assignedUserName,checkListMaintanenceDetailsNames,createdAt,createdById,createdByName,dateEnd,dateEndDate,dateStart,dateStartDate,dayType,deleted,description,duration,estimatedDuration,id,isAllDay,locationsId,locationsName,maintanenceTypeId,maintanenceTypeName,modifiedAt,modifiedById,modifiedByName,monthDay,name,onTheDay,parentId,parentType,periodInterval,periodType,priority,status,weekDay,filesIds,filesNames,filesTypes,imagesIds,imagesNames,imagesTypes,assignedUsersIds,assignedUsersNames,userId,userName,reminders,vendorcustomerIds,vendorcustomerNames,teamsIds,teamsNames",
    assetsSelect:"id,name,assignedUsersIds,assignedUsersNames,assetParentId,assetParentName,deleted,status,dateStart,dateEnd,isAllDay,duration,description,reminders,createdAt,modifiedAt,model,category,area,customBarcode,randomBarcode,barcodeType,barcode,warrantyEndDate,dateStartDate,dateEndDate,parentId,parentType,parentName,createdById,createdByName,modifiedById,modifiedByName,assignedUserId,assignedUserName,teamsIds,teamsNames,locationsId,locationsName,fileId,fileName,customersId,customersName,assetPartsIds,assetPartsNames,model",
    locationSelect:"id,name,deleted,description,createdAt,modifiedAt,xCordinate,yCordinate,addressStreet,addressCity,addressState,addressCountry,addressPostalCode,createdById,createdByName,modifiedById,modifiedByName,assignedUserId,assignedUserName,teamsIds,teamsNames,locationsParentId,locationsParentName,assignedUsersIds,assignedUsersNames,assetsIds,assetsNames,partsIds,partsNames,customersIds,customersNames,showCordinate,mapZoom",
    openImage: '?entryPoint=image&id=',
    downloadFile: '?entryPoint=download&id=',
    mapboxAccessToken: 'pk.eyJ1Ijoia3VicmFlcmJpbGVuIiwiYSI6ImNsajVzODAwZzA0d2kzam1wYWhtamwyemsifQ.XySr_1blVmcU4QOG7mm8AA',
    partsSelect:"assignedUserId,assignedUserName,barcode,barcodeType,createdAt,createdById,createdByName,customBarcode,customersId,customersName,deleted,description,fileId,fileName,id,locationsId,locationsName,modifiedAt,modifiedById,modifiedByName,name,periodicMaintanenceId,periodicMaintanenceName,randomBarcode,teamsIds,teamsNames,unitPrice,unitPriceConverted,unitPriceCurrency,partNumber,quantity,category,status,assignedUsersIds,assignedUsersNames,imagesIds,imagesNames,imagesTypes,unitId,unitName",
    partsDetailSelect:"id,name,deleted,description,createdAt,modifiedAt,quantity,minquantity,unitPrice,sumDetail,totalQuantity,unitPriceCurrency,createdById,createdByName,modifiedById,modifiedByName,assignedUserId,assignedUserName,teamsIds,teamsNames,partId,partName,locationsId,locationsName,unitPriceConverted,unitId,unitName",
    checkListSelect: 'assignedUserId,assignedUserName,checkListDetailsIds,checkListDetailsNames,createdAt,createdById,createdByName,deleted,description,id,modifiedAt,modifiedById,modifiedByName,name,teamsIds,teamsNames,workOrderssIds,workOrderssNames',
    customersSelect:"assetsIds,assetsNames,assignedUserId,assignedUserName,billingAddressCity,billingAddressCountry,billingAddressPostalCode,billingAddressState,billingAddressStreet,createdAt,createdById,createdByName,deleted,description,emailAddress,emailAddressData,emailAddressIsOptedOut,id,locationsId,locationsName,modifiedAt,modifiedById,modifiedByName,name,phoneNumber,phoneNumberData,phoneNumberIsOptedOut,shippingAddressCity,shippingAddressCountry,shippingAddressPostalCode,shippingAddressState,shippingAddressStreet,teamsIds,teamsNames,type,website,phoneCountry,rate,billingName,unitPriceCurrency,accountType,fileId,fileName",
    teamSelect: 'createdAt,deleted,id,layoutSetId,layoutSetName,modifiedAt,name,positionList,rolesIds,rolesNames',
    reportGrid: 'Report/action/run?id=',
    reportList: 'runList?id=',
    helpSelect:'id,name,deleted,description,createdAt,modifiedAt,category,createdById,createdByName,modifiedById,modifiedByName,assignedUserId,assignedUserName,teamsIds,teamsNames',
    contactUsSelect: 'id,status,date,parentName,createdById,assignedUserId,assignedUserName',
    streamSelect:'id,post,data,type,targetType,number,isGlobal,createdByGender,isInternal,createdAt,parentId,parentType,relatedId,relatedType,attachmentsIds,attachmentsNames,usersIds,usersNames,createdById,createdByName,attachmentsTypes',
    firebaseConfig: {
        apiKey: "AIzaSyDB9OqaqyrILdgtc89HgFlA-7GYifB5fV0",
        authDomain: "maintainv-3a8d2.firebaseapp.com",
        projectId: "maintainv-3a8d2",
        storageBucket: "maintainv-3a8d2.appspot.com",
        messagingSenderId: "790800004981",
        appId: "1:790800004981:web:69ae52a41342782827453c",
        measurementId: "G-G0DMDP0EJV"
    },
    appVersion: "VidaSmartV8"
};
















