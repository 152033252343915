import {ChangeDetectorRef, Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from "../services/authentication/authentication.service";
import {environment} from "../../../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // eğer backend isteği ise
        if (request.url.includes(environment.apiUrl) || request.url.includes('buyLidio')) {
            return next.handle(request);
        }

        // payment list için farklı bir user ile giriş yapılması gerekiyor.
        if (request.url == `${environment.baseApiUserUrl}/Payment`) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Basic cGF5bWVudDp6djk5dlNnTnFk`
                }
            });
            return next.handle(request);
        }

        // contact us için farklı bir user ile giriş yapılması gerekiyor.
        if (request.url.includes(`${environment.baseApiUserUrl}/ContactUs`) ||
            request.url.includes(`${environment.baseApiUserUrl}/Question`)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Basic Y29udGFjdHVzOm1MMDBIWFhuaUU=`
                }
            });
            return next.handle(request);
        }

        // normal
        const currentUser = this.authenticationService.getCurrentUser;
        const isLoggedIn = currentUser && currentUser.authData;
        if (isLoggedIn) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Basic ${currentUser.authData}`
                }
            });
        }
        return next.handle(request);
    }
}
