import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation,} from '@angular/core';
import {CoreService} from 'src/app/core/services/core.service';
import {MatDialog} from '@angular/material/dialog';
import {navItems} from '../sidebar/sidebar-data';
import {TranslateService} from '@ngx-translate/core';
import {Router, RouterModule} from '@angular/router';
import {MaterialModule} from 'src/app/material.module';
import {TablerIconsModule} from 'angular-tabler-icons';
import {FormsModule} from '@angular/forms';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {BrandingComponent,} from '../sidebar/branding.component';
import {NgFor, NgForOf, NgIf} from '@angular/common';
import {AuthenticationService} from "../../../../core/services/authentication/authentication.service";
import {NotificationService} from "../../../../core/services/notification/notification.service";
import {UtilsService} from "../../../../core/services/utils/utils.service";
import {environment} from 'src/environments/environment';

interface notifications {
    id: number;
    img: string;
    title: string;
    subtitle: string;
}

interface msgs {
    id: number;
    img: string;
    title: string;
    subtitle: string;
}

interface profiledd {
    id: number;
    img: string;
    title: string;
    subtitle: string;
    link: string;
    show: boolean;
}


@Component({
    selector: 'search-dialog',
    standalone: true,
    imports: [RouterModule, MaterialModule, TablerIconsModule, FormsModule, NgForOf],
    templateUrl: 'search-dialog.component.html',
})
export class AppSearchDialogComponent {
    searchText: string = '';
    navItems = navItems;

    navItemsData = navItems.filter((navitem) => navitem.displayName);

    // filtered = this.navItemsData.find((obj) => {
    //   return obj.displayName == this.searchinput;
    // });
}


@Component({
    selector: 'app-header',
    standalone: true,
    imports: [RouterModule, NgScrollbarModule, TablerIconsModule, MaterialModule, BrandingComponent, NgFor, NgIf, AppSearchDialogComponent],
    templateUrl: './header.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
    clickedToggle = false;
    @Input() showToggle = true;
    @Input() toggleChecked = false;
    @Output() toggleMobileNav = new EventEmitter<void>();
    @Output() toggleMobileFilterNav = new EventEmitter<void>();
    @Output() toggleCollapsed = new EventEmitter<void>();
    userInfo = this.authenticationService.getCurrentUser;

    ngOnInit() {
        this.getNotifications();
    }

    public selectedLanguage: any = {
        language: 'English',
        code: 'en',
        type: 'US',
        icon: 'assets/images/flag/icon-flag-en.svg',
    };

    constructor(
        private vsidenav: CoreService,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private router: Router,
        private translate: TranslateService,
        private notificationService: NotificationService,
        private utilsService: UtilsService
    ) {
        translate.setDefaultLang('en');
    }

    isAdmin = this.authenticationService.isAdmin;
    notificationList: any[] = [];
    navList = navItems;
    currentUser = this.authenticationService.getCurrentUser;

    openDialog() {
        const dialogRef = this.dialog.open(AppSearchDialogComponent);

        dialogRef.afterClosed().subscribe((result) => {
            //console.log(`Dialog result: ${result}`);
        });
    }

    getNotifications() {
        let filter = {}, max = 200, offset = 0;
        this.notificationService.getFullListNotifications(filter, offset, max).subscribe((data) => {
            // gelen datanın type'ı sadece message olanları filtrele
            this.notificationList = data.filter((item: any) => item.type === 'message');
            this.notificationList.forEach((item: any) => {
                item['displayMessage'] = item.message.replace('{entity}', item.data.entityName)
            })
        });
    }

    getNotificationCount(): number {
        return this.notificationList.filter((item: any) => item.read === false).length || 0;
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/auth/login']);
    }

    clickToggle() {
        this.clickedToggle = !this.clickedToggle;
        this.toggleCollapsed.emit();
        this.utilsService.nextToggle(this.clickedToggle)
    }

    getSourceOfAvatar(avatarId: any) {
        if (avatarId) {
            return `${environment.crmApiUrl}/${this.currentUser.licenseCode}/${environment.openImage}${avatarId}`;
          } else {
            return "assets/images/profile/noAvatar.png";
          }
      }

    openUserGuide() {
        window.open('/assets/user-guide.pdf', '_blank')
    }

    getNavListIcon(auth: any) {
        return this.navList.find((item) => item.auth === auth)?.iconName;
    }

    getNavListDisplayName(auth: any) {
        return this.navList.find((item) => item.auth === auth)?.displayName;
    }

    getNavListRoute(auth: any) {
        return this.navList.find((item) => item.auth === auth)?.route;
    }

    goToRoute(route: any, id: any) {
        //console.log(route, id, 'tıkladığım bu')
        this.router.navigate([route], { queryParams: { id: id } });
    }
}

