import {BreakpointObserver, MediaMatcher} from '@angular/cdk/layout';
import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatSidenav} from '@angular/material/sidenav';
import {CoreService} from 'src/app/core/services/core.service';
import {AppSettings} from 'src/app/app.config';
import {navItems} from './vertical/sidebar/sidebar-data';
import {NavService} from '../../core/services/nav.service';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {AppSearchDialogComponent, HeaderComponent,} from './vertical/header/header.component';
import {AppHorizontalHeaderComponent} from './horizontal/header/header.component';
import {AppHorizontalSidebarComponent} from './horizontal/sidebar/sidebar.component';
import {SidebarComponent} from './vertical/sidebar/sidebar.component';
import {AppBreadcrumbComponent} from './shared/breadcrumb/breadcrumb.component';
import {CustomizerComponent} from './shared/customizer/customizer.component';
import {MaterialModule} from 'src/app/material.module';
import {Router, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {AppNavItemComponent} from './vertical/sidebar/nav-item/nav-item.component';
import {AuthenticationService} from 'src/app/core/services/authentication/authentication.service';
import {SidebarModule} from 'primeng/sidebar';
import {ContactUsModule} from 'src/app/main/contact-us/contact-us.module';

const MOBILE_VIEW = 'screen and (max-width: 768px)';
const TABLET_VIEW = 'screen and (min-width: 769px) and (max-width: 1024px)';
const MONITOR_VIEW = 'screen and (min-width: 1024px)';

@Component({
    selector: 'app-full',
    templateUrl: './full.component.html',
    standalone: true,
    imports: [
        NgScrollbarModule,
        HeaderComponent,
        AppHorizontalHeaderComponent,
        AppHorizontalSidebarComponent,
        SidebarComponent,
        AppBreadcrumbComponent,
        AppSearchDialogComponent,
        CustomizerComponent,
        MaterialModule,
        RouterModule,
        CommonModule,
        AppNavItemComponent,
        SidebarModule,
        ContactUsModule
    ],
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class FullComponent implements OnInit {
    navItems: any = [];
    @ViewChild('leftsidenav')
    public sidenav: MatSidenav;
    public currentUser: any;
    public day = 0;
    //get options from service
    options = this.settings.getOptions();
    navopt = this.navService.showClass;
    private layoutChangesSubscription = Subscription.EMPTY;
    private isMobileScreen = false;
    private isContentWidthFixed = true;
    private isCollapsedWidthFixed = false;
    private htmlElement!: HTMLHtmlElement;

    get isOver(): boolean {
        return this.isMobileScreen;
    }

    constructor(
        private settings: CoreService,
        private mediaMatcher: MediaMatcher,
        private navService: NavService,
        private breakpointObserver: BreakpointObserver,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
        this.htmlElement = document.querySelector('html')!;
        this.layoutChangesSubscription = this.breakpointObserver
            .observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW])
            .subscribe((state) => {
                // SidenavOpened must be reset true when layout changes
                this.options.sidenavOpened = true;
                this.isMobileScreen = state.breakpoints[MOBILE_VIEW];
                if (this.options.sidenavCollapsed == false) {
                    this.options.sidenavCollapsed = state.breakpoints[TABLET_VIEW];
                }
                this.isContentWidthFixed = state.breakpoints[MONITOR_VIEW];
            });

        // Initialize project theme with options
        this.receiveOptions(this.options);
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.getNavItems();
        this.userTypeControl();
    }

    ngOnDestroy() {
        this.layoutChangesSubscription.unsubscribe();
    }

    toggleCollapsed() {
        this.isContentWidthFixed = false;
        this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
        this.resetCollapsedState();
    }

    resetCollapsedState(timer = 400) {
        setTimeout(() => this.settings.setOptions(this.options), timer);
    }

    onSidenavClosedStart() {
        this.isContentWidthFixed = false;
    }

    onSidenavOpenedChange(isOpened: boolean) {
        this.isCollapsedWidthFixed = !this.isOver;
        this.options.sidenavOpened = isOpened;
        this.settings.setOptions(this.options);
    }

    receiveOptions(options: AppSettings): void {
        this.options = options;
        this.toggleDarkTheme(options);
    }

    toggleDarkTheme(options: AppSettings) {
        if (options.theme === 'dark') {
            this.htmlElement.classList.add('dark-theme');
            this.htmlElement.classList.remove('light-theme');
        } else {
            this.htmlElement.classList.remove('dark-theme');
            this.htmlElement.classList.add('light-theme');
        }
    }

    openContactUs() {
        this.router.navigate(['/contact-us']);
    }

    getNavItems() {
        this.navItems = [];
        navItems.forEach((item: any) => {
            if (this.authenticationService.isUserAuthorizedForModule(item.auth) || item.auth == 'None') {
                this.navItems.push(item);
            }
        })
    }

    userTypeControl() {
        let currentUser = this.authenticationService.getCurrentUser;
        let url = this.router.url;
        if (url.includes('/dashboard')) {
            return;
        }
        if (currentUser && currentUser.userType == 'Expired') {
            navItems.forEach((item: any) => {
                if (url.includes(item.route)) {
                    this.router.navigate(['/dashboard']);
                }
            })
        }
    }

}
