import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {NotificationService} from "../services/notification/notification.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    @BlockUI() blockUI: NgBlockUI;

    constructor(private notificationService: NotificationService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    errorMsg = `${error.statusText}`;
                    this.blockUI.stop();

                    // şifre sıfırlama linki gönderirken hata alındığında
                    if (error.status === 404 && request.url.includes('passwordChangeRequest')) {
                        // user not found
                        this.getNotification('User not found');
                        return throwError(errorMsg);
                    }

                    // şifre sıfırlama linki gönderirken hata alındığında
                    if (error.status === 403 && request.url.includes('passwordChangeRequest')) {
                        // zaten şifre sıfırlama linki gönderilmiş
                        this.getNotification('Password reset link has already been sent');
                        return throwError(errorMsg);
                    }

                    // üye olurken hata alındığında
                    if (error.status === 409 && request.url.includes('sendVerifyCode')) {
                        // email already registered
                        this.getNotification('Email already registered');
                        return throwError(errorMsg);
                    }

                    // kullanıcı silerken hata aldığında
                    if (error.status === 500 && request.url.includes('user/deleteUser')) {
                        this.getNotification(error.error.message);
                        return throwError(error.error.message);
                    }

                    if (!request.url.includes('v1/App/user')) {
                        this.getNotification(errorMsg);
                        return throwError(errorMsg);
                    }
                    return throwError(errorMsg);
                })
            )
    }

    getNotification(message: any) {
        this.notificationService.showError('Error', message);
    }
}
