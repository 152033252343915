import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import {AuthGuard} from "./core/guard/auth.guard";
import {AppErrorComponent} from "./pages/authentication/error/error.component";

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren:() => import('./main/main.module').then((m) => m.MainModule),
      },
      {
        path: 'dashboards',
        loadChildren: () =>
            import('./pages/dashboards/dashboards.module').then(
                (m) => m.DashboardsModule
            ),
      },
      {
        path: 'ui-components',
        loadChildren: () =>
            import('./pages/ui-components/ui-components.module').then(
                (m) => m.UicomponentsModule
            ),
      },
      {
        path: 'forms',
        loadChildren: () =>
            import('./pages/forms/forms.module').then((m) => m.FormModule),
      },
      {
        path: 'charts',
        loadChildren: () =>
            import('./pages/charts/charts.module').then((m) => m.ChartsModule),
      },
      {
        path: 'apps',
        loadChildren: () =>
            import('./pages/apps/apps.module').then((m) => m.AppsModule),
      },
      {
        path: 'widgets',
        loadChildren: () =>
            import('./pages/widgets/widgets.module').then((m) => m.WidgetsModule),
      },
      {
        path: 'tables',
        loadChildren: () =>
            import('./pages/tables/tables.module').then((m) => m.TablesModule),
      },
      {
        path: 'theme-pages',
        loadChildren: () =>
            import('./pages/theme-pages/theme-pages.module').then(
                (m) => m.ThemePagesModule
            ),
      },
      {
        path: 'administration',
        loadChildren: () =>
            import('./main/administration/administration.module').then(
                (m) => m.AdministrationModule
            ),
      },
      {
        path: 'account',
        loadChildren: () =>
            import('./main/account/account.module').then(
                (m) => m.AccountModule
            ),
      },
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        path: 'landingpage',
        loadChildren: () =>
          import('./pages/theme-pages/landingpage/landingpage.module').then(
            (m) => m.LandingPageModule
          ),
      },
    ],
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then((m) => m.TermsModule)
  },
  {
    path: 'auto-login',
    loadChildren: () => import('./auto-login/auto-login.module').then((m) => m.AutoLoginModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    component: AppErrorComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
