<mat-toolbar class="topbar">
    <app-branding></app-branding>

    <!-- --------------------------------------------------------------- -->
    <!-- Desktop Menu -->
    <button mat-icon-button (click)="clickToggle()" *ngIf="showToggle" class="d-flex justify-content-center">
        <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>

    <!-- Mobile Menu -->
    <button mat-icon-button (click)="toggleMobileNav.emit()" *ngIf="!showToggle" class="d-flex justify-content-center">
        <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>
    <!-- --------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------- -->
    <!--  Search -->
    <!-- --------------------------------------------------------------- -->
    <button mat-icon-button (click)="openDialog()" class="d-flex justify-content-center">
        <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
    </button>

    <span class="flex-1-auto"></span>

    <!-- --------------------------------------------------------------- -->
    <!-- langugage Dropdown -->
    <!-- --------------------------------------------------------------- -->
    <!-- <button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
        <img [src]="selectedLanguage.icon" class="rounded object-cover icon-20" />
      </button>
      <mat-menu #flags="matMenu" class="cardWithShadow">
        <button
          mat-menu-item
          *ngFor="let lang of languages"
          (click)="changeLanguage(lang)"
        >
          <div class="d-flex align-items-center">
            <img [src]="lang.icon" class="rounded object-cover m-r-8 icon-20" />
            <span class="mat-subtitle-1 f-s-14">{{ lang.language }}</span>
          </div>
        </button>
      </mat-menu>  -->

    <!-- ------------------------- help button------------------------ -->
    <button mat-icon-button class="d-flex" *ngIf="false" (click)="openUserGuide()">
        <img *ngIf="false" [src]="'assets/images/svgs/user-guide.png'" width="40" alt=""/>
        <img *ngIf="false" [src]="'assets/images/svgs/manual-book.png'" width="40" alt=""/>
    </button>
    <!-- --------------------------------------------------------------- -->

    <!-- ------------------------- help button------------------------ -->
    <button mat-icon-button class="d-flex" [routerLink]="'/help'">
        <i-tabler name="help-square-rounded" class="icon-24 "></i-tabler>
    </button>
    <!-- --------------------------------------------------------------- -->

    <!-- Notification Dropdown -->
    <!-- --------------------------------------------------------------- -->
    <button mat-icon-button [matMenuTriggerFor]="notificationmenu" aria-label="Notifications">
        <mat-icon [matBadge]="getNotificationCount() > 0?getNotificationCount():null" matBadgeOverlap="false"
                  matBadgeColor="warn"
                  class="header-badge">message
        </mat-icon>
    </button>
    <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">
        <div class="d-flex align-items-center p-x-32 p-y-16">
            <h6 class="f-s-16 f-w-600 m-0">Notifications</h6>
            <span class="m-l-auto">
        <span *ngIf="false" class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">{{getNotificationCount()}}
            new</span>
      </span>
        </div>
        <div style="height: 60vh !important; overflow: scroll" *ngIf="notificationList.length > 0">
            <button mat-menu-item class="p-x-32 p-y-16" *ngFor="let notification of notificationList"
                    (click)="goToRoute(getNavListRoute(notification.relatedType), notification.data.entityId)">
                <div class="d-flex align-items-center">
                    <i-tabler name="{{getNavListIcon(notification.relatedType)}}"></i-tabler>
                    <div class="m-l-16">
                        <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
                            {{getNavListDisplayName(notification.relatedType)}}
                        </h5>
                        <span>{{ notification.displayMessage }}</span>
                    </div>
                </div>
            </button>
        </div>
        <div class="p-y-12 p-x-32" *ngIf="notificationList.length == 0">
            <div>
                No notifications
            </div>
        </div>
    </mat-menu>

    <!-- --------------------------------------------------------------- -->
    <!-- Messages Dropdown -->
    <!-- --------------------------------------------------------------- -->
    <!--  <button mat-icon-button [matMenuTriggerFor]="msgmenu" aria-label="Messages">
        <mat-icon
          matBadge="2"
          matBadgeOverlap="false"
          matBadgeColor="warn"
          class="header-badge"
          >email</mat-icon
        >
      </button>
      <mat-menu #msgmenu="matMenu" class="topbar-dd cardWithShadow">
        <div class="d-flex align-items-center p-x-32 p-y-16">
          <h6 class="f-s-16 f-w-600 m-0">Messages</h6>
          <span class="m-l-auto">
            <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12"
              >5 new</span
            >
          </span>
        </div>
        <button mat-menu-item class="p-x-32 p-y-16" *ngFor="let msg of msgs">
          <div class="d-flex align-items-center">
            <img [src]="msg.img" class="rounded-circle" width="48" />
            <div class="m-l-16">
              <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
                {{ msg.title }}
              </h5>
              <span>{{ msg.subtitle }}</span>
            </div>
          </div>
        </button>
        <div class="p-y-12 p-x-32">
          <button mat-stroked-button color="primary" class="w-100">
            See all messages
          </button>
        </div>
      </mat-menu>-->
    <!-- --------------------------------------------------------------- -->
    <!-- profile Dropdown -->
    <!-- --------------------------------------------------------------- -->
    <button mat-icon-button [matMenuTriggerFor]="profilemenu" aria-label="Notifications">
        <img src="{{getSourceOfAvatar(userInfo.avatarId)}}" class="rounded-circle object-cover mb-3" width="55"/>
    </button>
    <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
        <div class="row">
            <div class="col-12">
                <div class="p-x-32 p-y-16">
                    <h6 class="f-s-16 f-w-600 m-0">User Profile</h6>

                    <div class="d-flex align-items-center m-t-16">
                        <img [routerLink]="'/account'" src="{{getSourceOfAvatar(userInfo.avatarId)}}"
                             class="rounded-circle cursor-pointer" width="50"/>
                        <div class="m-l-16 cursor-pointer" [routerLink]="'/account'">
                            <h6 class="f-s-14 f-w-600 m-0">{{userInfo?.name}}</h6>
                            <span class="f-s-14 d-block m-b-4">{{userInfo?.title}}</span>
                        </div>
                    </div>
                    <div class="d-flex m-t-16 b-b-1 p-b-24">
            <span class="d-flex align-items-center">
                <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
                {{userInfo?.emailAddress}}
              </span>
                    </div>
                </div>
                <div class="p-x-32">
                    <a class="p-y-16 text-decoration-none d-block text-hover-primary"
                       [routerLink]="'/account'">
                        <div class="d-flex align-items-center">
                            <button mat-mini-fab
                                    class="text-primary bg-light-primary shadow-none rounded">
                                <img src="{{getSourceOfAvatar(userInfo.avatarId)}}" width="20"/>
                            </button>

                            <div class="m-l-16">
                                <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                                    Profile
                                </h5>
                            </div>
                        </div>
                    </a>
                    <a class="p-y-16 text-decoration-none d-block text-hover-primary" *ngIf="isAdmin"
                       [routerLink]="'/administration'">
                        <div class="d-flex align-items-center">
                            <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                                <img [src]="'assets/images/svgs/icon-tasks.svg'" width="20" alt=""/>
                            </button>

                            <div class="m-l-16">
                                <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                                    Admin
                                </h5>
                                <span class="mat-body-1">
                  Administration
                </span>
                            </div>

                        </div>
                    </a>

                    <a class="p-y-16 text-decoration-none d-block text-hover-primary cursor-pointer"
                       (click)="openUserGuide()">
                        <div class="d-flex align-items-center">
                            <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                                <img *ngIf="false" [src]="'assets/images/svgs/user-guide.png'" width="35" alt=""/>
                                <img *ngIf="false" [src]="'assets/images/svgs/info-2.png'" width="30" alt=""/>
                                <img *ngIf="false" [src]="'assets/images/svgs/book.png'" width="30" alt=""/>
                                <img *ngIf="false" [src]="'assets/images/svgs/user_guide.png'" width="30" alt=""/>
                                <img *ngIf="true" [src]="'assets/images/svgs/user_guide_2.png'" width="30" alt=""/>
                                <img *ngIf="false" [src]="'assets/images/svgs/user_guide_download.png'" width="30"
                                     alt=""/>
                            </button>

                            <div class="m-l-16">
                                <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                                    User Guide
                                </h5>
                                <span class="mat-body-1">
                </span>
                            </div>

                        </div>
                    </a>
                </div>
                <div class="p-y-12 p-x-32">
                    <a (click)="logout()" mat-stroked-button color="primary" class="w-100">Logout</a>
                </div>
            </div>
        </div>
    </mat-menu>
</mat-toolbar>
