import {Component} from '@angular/core';
import {BlockUiComponent} from './main/block-ui';
import {AuthenticationService} from "./core/services/authentication/authentication.service";
import {environment} from "../environments/environment";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {

    blockTemplate = BlockUiComponent;
    currentUser: any = this.authenticationService.getCurrentUser

    constructor(private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        if (this.currentUser.appVersion) {
            if (this.currentUser.appVersion !== environment.appVersion) {
                localStorage.clear();
                window.location.reload();
            }
        } else {
            localStorage.clear();
            window.location.reload();
        }
    }

}
