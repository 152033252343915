import {Injectable} from '@angular/core';
import * as moment from "moment/moment";
import {Subject} from "rxjs";
import {AuthenticationService} from "../authentication/authentication.service";
import * as momentTimezone from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    public showToggle = new Subject<any>();

    constructor(private authenticationService: AuthenticationService) {
    }

    addThreeHoursFromDate(date: any) {
        let settings = JSON.parse(localStorage.getItem('settings' || '{}') || '{}');
        if (date && settings) {
            const timeZone = settings.timeZone || 'Europe/Istanbul';
            const currentTime = momentTimezone.tz(timeZone);
            const gmtOffset = currentTime.utcOffset() / 60;
            let newDate = new Date(date);
            newDate.setHours(newDate.getHours() + gmtOffset);
            return moment(newDate).format('YYYY-MM-DD HH:mm:ss');
        }
        return date;

    }

    subtractThreeHoursFromDate(date: any) {
        let settings = JSON.parse(localStorage.getItem('settings' || '{}') || '{}');
        if (date && settings) {
            const timeZone = settings.timeZone || 'Europe/Istanbul';
            const currentTime = moment.tz(timeZone);
            const gmtOffset = currentTime.utcOffset() / 60;
            const absoluteGmtOffset = gmtOffset * -1;
            let newDate = new Date(date);
            newDate.setHours(newDate.getHours() + absoluteGmtOffset);
            return moment(newDate).format('YYYY-MM-DD HH:mm:ss');
        }
        return date;
    }

    convertDate(date: any) {
        if (date) {
            let newDate = new Date(date);
            return moment(newDate).format('YYYY-MM-DD HH:mm:ss');
        }
        return date;
    }

    convertSecondsToTime(seconds: number) {
        if (seconds > 0) {
            const days = Math.floor(seconds / (3600 * 24));
            const hours = Math.floor((seconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);

            let time = '';
            if (days > 0) time += `${days}d `;
            if (hours > 0) time += `${hours}h `;
            if (minutes > 0) time += `${minutes}m `;
            return time;
        }
        return seconds;
    }

    onToggleSubscriber() {
        return this.showToggle.asObservable();
    }

    nextToggle(value: any) {
        this.showToggle.next(value);
    }
}
