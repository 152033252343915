import { Injectable } from '@angular/core';
import { BaseService } from "../base/base.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import {AuthenticationService} from "../authentication/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService {

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    super(http, 'ContactUs', authService);
  }

  getContacts(filter: any, offset?: number, maxSize?: number, select?: string) {
    let whereQuery = '';
    let whereCounter = 0;
    if (filter) {
      let assignedUserId = filter['assignedUserId'];
      let dateStart = filter['dateStart'];
      if (assignedUserId) {
        whereQuery += `&where[${whereCounter}][type]=equals&where[${whereCounter}][attribute]=assignedUserId&where[${whereCounter}][value]=${assignedUserId}`;
        whereCounter++;
      }
      if (dateStart) {
        whereQuery += `&where[${whereCounter}][type]=between&where[${whereCounter}][attribute]=dateStart&where[${whereCounter}][value][]=${dateStart[0]}&where[${whereCounter}][value][]=${dateStart[1]}&where[${whereCounter}][dateTime]=true&where[${whereCounter}][timeZone]=Europe/Moscow`;
        whereCounter++;
      }

    }
    return this.getFullListByWhere(select, whereQuery, offset, maxSize, 'ContactUs', environment.baseApiUserUrl);
  }

  getContactUsDetails(id: string) {
    let url = `${environment.baseApiUserUrl}/ContactUs/${id}/contactUsDetails?select=name,assignedUserId,assignedUserName,questionId,questionName,answer&maxSize=200&offset=0&orderBy=createdAt&order=asc`;
    return this.http.get(url).pipe(map((data: any) => {
      let sorted = data.list.sort((a:any, b:any) => {
        return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
      });
      return sorted.reverse();
    }));
  }

  getContactsFullList(filter: any, offset?: number, maxSize?: number, select?: string) {
    let fullListSubject = new Subject<any>();
    let fullList: any[] = [];
    this.getContacts(filter, offset, maxSize, select).subscribe((data: any) => {
      let offset = 0;
      let maxSize = 200;
      let workOrderTotal = data.total;
      for (offset; offset < data.total; offset += maxSize) {
        this.getContacts(filter, offset, maxSize, select).subscribe(data => {
          data.list.forEach((item: any) => {
            fullList.push(item)
          })
          if (fullList.length == workOrderTotal) {
            let sorted = fullList.sort((a, b) => {
              return <any>new Date(b.dateStart) - <any>new Date(a.dateStart);
            });
            fullListSubject.next(sorted);
          }
        })
      }
      if (data.total === 0) {
        fullListSubject.next([]);
      }
    })
    return fullListSubject.asObservable();
  }

  getQuestionByKey(key: string) {
    let keyArray: any[] = key.split(" ");
    let whereQuery = `&where[0][type]=arrayAnyOf&where[0][attribute]=key`;
    keyArray.forEach((item: any) => {
      whereQuery += `&where[0][value][]=${item}`;
    });
    let url = `${environment.baseApiUserUrl}/Question?select=name,answer,key${whereQuery}`;
    return this.http.get(url).pipe(map((data: any) => {
      return data.list;
    }));
  }

}
