import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NgScrollbarModule} from 'ngx-scrollbar';
import {TablerIconsModule} from 'angular-tabler-icons';
import {MaterialModule} from 'src/app/material.module';
import {Router, RouterModule} from '@angular/router';
import {AppNavItemComponent} from './nav-item/nav-item.component';
import {NavService} from "../../../../core/services/nav.service";
import {AuthenticationService} from "../../../../core/services/authentication/authentication.service";
import * as moment from "moment/moment";


@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [
        NgScrollbarModule,
        TablerIconsModule,
        MaterialModule,
        RouterModule,
        AppNavItemComponent, CommonModule
    ],
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
    navopt = this.navService.showClass;
    user: any;
    day: any;

    currentUrl = this.router.url;

    constructor(public navService: NavService,
                private router: Router,
                private authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser;
        setTimeout(() => {
            this.setDate();
        }, 100)
    }

    setDate() {
        let currentUser = this.authenticationService.getCurrentUser;
        if (currentUser) {
            let currentDate = new Date();
            let expireDate = new Date(moment(currentUser.expireDate, 'YYYY-MM-DD').format('YYYY-MM-DD:HH:mm:ss'));
            if(expireDate < currentDate) {
                this.day = -1;
                return;
            } else {
                let diff = Math.abs(expireDate.getTime() - currentDate.getTime());
                this.day = Math.ceil(diff / (1000 * 3600 * 24)) || 0;
            }

        }
    }
}
