import {Injectable} from '@angular/core';
import Swal from 'sweetalert2'
import {MessageService} from "primeng/api";
import {BaseService} from "../base/base.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Subject} from "rxjs";
import {AuthenticationService} from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BaseService {
    constructor(private http: HttpClient, private messageService: MessageService, private authService: AuthenticationService) {
        super(http, `Notification`, authService)
    }

    getNotifications(filter: any, offset?: number, maxSize?: number, select?: string) {
        let url = `${environment.crmApiUrl}/${this.authService.getCurrentUser.licenseCode}/api/v1`;
        let whereQuery = '';
        let whereCounter = 0;
        if (!select) {
            select = environment.customersSelect;
        }
        return this.getFullListByWhere(select, whereQuery, offset, maxSize, 'Notification', url);
    }

    getFullListNotifications(filter: any, offset?: number, maxSize?: number, select?: string) {
        let fullListSubject = new Subject<any>();
        if (!this.authService.isUserAuthorizedForEdit('Notification') && !this.authService.isUserAuthorizedForRead('Notification')) {
            return fullListSubject.asObservable();
        }
        let fullList: any[] = [];
        this.getNotifications(filter, offset, maxSize, select).subscribe((data: any) => {
            let offset = 0;
            let maxSize = 200;
            let workOrderTotal = data.total;
            for (offset; offset < data.total; offset += maxSize) {
                this.getNotifications(filter, offset, maxSize, select).subscribe(data => {
                    data.list.forEach((item: any) => {
                        fullList.push(item)
                    })
                    if (fullList.length == workOrderTotal) {
                        let sorted = fullList.sort((a, b) => {
                            return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
                        });
                        fullListSubject.next(sorted);
                    }
                })
            }
            if (data.total === 0) {
                fullListSubject.next([]);
            }
        })
        return fullListSubject.asObservable();
    }


    showErrorCenter(title: string, message: string, timer?: number,) {
        Swal.fire({
            title: title,
            text: message,
            color: 'rgb(37,37,37)',
            confirmButtonColor: '#0047B9',
            background: 'rgb(255,255,255)',
            icon: 'error',
            timer: timer || 3000,
        });
    }

    showWarningCenter(title: string, message: string, timer?: number) {
        Swal.fire({
            text: message,
            color: 'rgb(37,37,37)',
            confirmButtonColor: '#0047B9',
            background: 'rgb(255,255,255)',
            icon: 'warning',
            timer: timer || 3000,
        });
    }

    showSuccessCenter(title: string, message: string, timer?: number,) {
        Swal.fire({
            text: message,
            color: 'rgb(37,37,37)',
            confirmButtonColor: '#0047B9',
            background: 'rgb(255,255,255)',
            icon: 'success',
            timer: timer || 3000,
        })
    }

    showSuccess(title: string, message: string) {
        this.messageService.add({severity: 'success', summary: title, detail: message});
    }

    showError(title: string, message: string) {
        this.messageService.add({severity: 'error', summary: title, detail: message});
    }

    showWarning(title: string, message: string) {
        this.messageService.add({severity: 'warn', summary: title, detail: message});
    }

    showInfo(title: string, message: string) {
        this.messageService.add({severity: 'info', summary: title, detail: message});
    }

}
