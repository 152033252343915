<div class="flex">
  <div class="user-profile profile-bar" style="background: url(assets/images/backgrounds/profile-bg.jpg) no-repeat">
    <!-- User profile image -->
    <div class="profile-img" *ngIf="false">
      <img src="assets/images/profile/profile.png" alt="user" width="50" class="rounded-circle" />
    </div>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div *ngIf="day <= 15 || user.userType == 'Demo'" class="profile-text">
      <div class="p-2 text-center" style="background: #FFD3D3; color: #FE273A; font-size: 13px">
        <img src="assets/images/backgrounds/payment-error.png" width="25" class="mx-2">
        <span *ngIf="day > 0">{{day}} days left your free!</span>
        <span *ngIf="day <= 0">Your package has expired.</span>
        <span class="cursor-pointer fw-bold text-decoration-underline" [routerLink]="'/subscription'">
          Upgrade
        </span>
      </div>
    </div>
    <mat-menu #sdprofile="matMenu" xPosition="after" class="cardWithShadow profile-dd">
      <button mat-menu-item>
        <mat-icon class="d-flex align-items-center"><i-tabler name="settings"
            class="icon-18 d-flex"></i-tabler></mat-icon>
        Settings
      </button>
      <button mat-menu-item>
        <mat-icon class="d-flex align-items-center"><i-tabler name="users" class="icon-18 d-flex"></i-tabler></mat-icon>
        Profile
      </button>
      <button mat-menu-item>
        <mat-icon class="d-flex align-items-center"><i-tabler name="bell-off"
            class="icon-18 d-flex"></i-tabler></mat-icon>
        Disable notifications
      </button>
      <a mat-menu-item [routerLink]="['/authentication/side-login']"><mat-icon
          class="d-flex align-items-center"><i-tabler name="logout" class="icon-18 d-flex"></i-tabler></mat-icon>
        Sign Out</a>
    </mat-menu>
  </div>
</div>
