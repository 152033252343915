import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContactService } from "../../../core/services/contact/contact.service";
import { AuthenticationService } from "../../../core/services/authentication/authentication.service";
import { environment } from "../../../../environments/environment";
import { UtilsService } from "../../../core/services/utils/utils.service";
import * as moment from 'moment';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit,AfterViewChecked {
    @ViewChild('scroll') private myScrollContainer: ElementRef;
    openChat = false;
    message: any;
    contacts: any[] = [];

    selectedContact : any={};
    contactDetail: any[] = [];
    isTypingBot = false;
    allContacts = false;
    currentUser: any;

    constructor(private contactService: ContactService,
        private utilsService: UtilsService,
        private authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.currentUser = this.authenticationService.getCurrentUser;
        this.getContacts();
    }


    // kullanıcı sohbetleri
    getContacts() {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() - 1);
        this.contacts = [];
        let filter = {
            "assignedUserId": this.currentUser.id,
            "dateStart": [moment(newDate).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
        }, offset = 0, maxSize = 200;
        this.contactService.getContactsFullList(filter, offset, maxSize).subscribe(data => {
            this.contacts = data;
            if (this.contacts.length == 0) {
                // eğer hiç sohbet yoksa yeni sohbet aç
                this.addNewContact();
            } else {
                // eğer sohbet varsa son sohbeti getir
                this.selectedContact = data[0];
                this.getContactDetail();
            }
        });
    }
    // chat detay getirme
    getContactDetail() {
        if (this.selectedContact.id) {
            this.contactService.getContactUsDetails(this.selectedContact.id).subscribe(data => {
                this.contactDetail = data;
            });
        } else {
            this.contactDetail = [];
        }
    }
    //her zaman sayfanın en altının gelmesi
    ngAfterViewChecked() {
        this.scrollToBottom();
    }
    scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch(err) { }
    }
    // chat açma kapama
    changeVisibility() {
        this.openChat = !this.openChat;
        if (this.openChat) {
            // eğer seçili bir sohbet yoksa sohbetleri getir eğer varsa son açık olan sohbeti getir
            if (!this.selectedContact.id) {
                this.getContacts();
            }
        }
    }

    // kullanıcı mesajları
    sendMessage() {
        let obj = { 'assignedUserId': this.currentUser.id, 'name': this.message };
        this.contactDetail = [...this.contactDetail, obj];
        //let contactDetailLength = this.contactDetail.length;
        // this.contactDetail[contactDetailLength] = {};
        // this.contactDetail[contactDetailLength]['assignedUserId'] = this.currentUser.id;
        // this.contactDetail[contactDetailLength]['name'] = this.message;
        this.isTypingBot = true;
        this.answerMessage(this.message);
        this.message = '';
    }

    // bot cevapları
    answerMessage(message: any) {
        this.isTypingBot = true;
        setTimeout(() => {
            this.contactService.getQuestionByKey(message).subscribe(res => {
                this.isTypingBot = false;
                this.contactDetail[this.contactDetail.length - 1].questionId = res.length > 0 ? res[0].id : "65018f111227c9821";
                this.contactDetail[this.contactDetail.length - 1].questionName = res.length > 0 ? res[0].name : "Sorry";
                this.contactDetail[this.contactDetail.length - 1].answer = res.length > 0 ? res[0].answer : "Sorry... I don't understand what you're saying. Please try again.";
                let object = {
                    name: this.contactDetail[this.contactDetail.length - 1].answer,
                    contactUsDetailsListHolder: this.contactDetail,
                    dateStart: this.utilsService.convertDate(new Date()),
                }
                this.contactService.put(object, this.selectedContact.id, environment.baseApiUserUrl).subscribe(data => {
                    if (data) {
                       // this.contactDetail = data.contactUsDetailsListHolder;
                       this.getContactDetail();
                    }
                }, error => {
                    this.isTypingBot = false;
                })
            }, error => {
                this.isTypingBot = false;
            })
        }, 1000)
    }

    // enter tuşuna basıldığında mesaj gönderme
    onKey(event: any) {
        if (event.keyCode === 13 && event.key === 'Enter') {
            this.sendMessage();
        }
    }

    // seçilen sohbeti getirme
    selectContact(contact: any) {
        this.contactDetail = [];
        this.selectedContact = contact;
        this.allContacts = false;
        this.getContactDetail();
    }

    // tüm sohbetlere geri dönme
    backToAllContact() {
        this.allContacts = true;
        this.getContacts();
    }

    // yeni sohbet ekleme
    addNewContact() {
        let objectDetail = [
            {
                "assignedUserId": this.currentUser.id,
                "questionId": "65018f5f443f765e8",
                "questionName": "Start-1",
                "answer": "Hello, I'm MaintainV Assistant, I'm here to support you. 😊"
            },
            {
                "assignedUserId": this.currentUser.id,
                "questionId": "65018f7702abcd2c8",
                "questionName": "Start-2",
                "answer": "How can I help?"
            }
        ]
        let object = {
            name: 'New Chat',
            assignedUserId: this.currentUser.id,
            dateStart: this.utilsService.convertDate(new Date()),
            contactUsDetailsListHolder: objectDetail,
            status: "active"
        };
        this.contactService.post(object, environment.baseApiUserUrl).subscribe(data => {
            this.selectContact(data);
        });
    }
}
