import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {UserType} from "../../models/userType";
import {Role} from "../../models/role";
import * as CryptoJS from 'crypto-js';
import {Router} from "@angular/router";
import * as moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    public currentUserObservable: Observable<any>;
    public currentUser: any = {
        appVersion: environment.appVersion
    };
    private currentUserSubject: BehaviorSubject<any>;

    public secretKey = 'VidaSmart2023'
    public encSecretKey = '35GgkPA4ldEffbNR6lIC5HUrRFoe59Pa'

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<any>(this.getCurrentUser);
        this.currentUserSubject.subscribe(
            (user) => {
                this.currentUser = user;
                if (user) {
                    // localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                    localStorage.setItem('currentUser', this.encryptData(JSON.stringify(this.currentUser)));
                }
                this.currentUserObservable = this.currentUserSubject.asObservable();
            }
        );
    }

    login(username: any, password: any) {
        let url = `${environment.api}/login`;
        let body = {
            username: this.encryptLicenseCode(username),
            password: this.encryptLicenseCode(password)
        }
        return this.http.post<any>(url, body).pipe(map(res => {
            if (!!res && !!res.user) {
                let userRole = new Role();
                let userRoleFields = new Role();
                userRole.data = res.acl.table;
                userRoleFields.data = res.acl.fieldTable;
                this.currentUser = this.convertResponseToUser(res.user);
                this.currentUser.authData = btoa(`${username}:${password}`)
                this.currentUser.roles = userRole;
                this.currentUser.licenseCode = this.decryptLicenseCode(res.user.licenseCode);
                this.currentUser.url = `${environment.crmApiUrl}/${this.currentUser.licenseCode}/api/v1`;
                this.currentUser.fieldRole = userRoleFields;
                this.currentUser.appVersion = environment.appVersion
                this.currentUserSubject.next(this.currentUser);
                return res.user;
            } else {
                return res;
            }
        }))
    }

    createUser(data: any) {
        let url = `${environment.apiUrl}/createUser`;
        return this.http.post(url, data).pipe(map((data: any) => {
            return data;
        }));
    }

    generateLicense(data: any) {
        let url = `${environment.apiUrl}/generateLicense`;
        return this.http.post(url, data).pipe(map((data: any) => {
            return data;
        }));
    }

    sendVerifyCode(data: any) {
        let url = `${environment.apiUrl}/sendVerifyCode`;
        return this.http.post(url, data).pipe(map((data: any) => {
            return data;
        }));
    }

    forgotPasswordSendEmail(forgotPassword: any) {
        return this.http.post<any>(`${environment.baseApiUserUrl}/${environment.appUser}/${environment.forgotPassword}`,
            forgotPassword, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    changePasswordUser(data: any) {
        let licenseCode = this.encryptLicenseCode(this.currentUser.licenseCode);
        let userId = this.currentUser.id;
        let url = `${environment.apiUrl}/changePasswordUser`;
        data['licenseCode'] = licenseCode;
        data['userId'] = userId;
        return this.http.post<any>(url, data, {}).pipe(map((data: any) => {
            return data;
        }));
    }

    forgotPassword(data: any) {
        let url = `${environment.apiUrl}/changePassword`;
        return this.http.post(url, data).pipe(map((data: any) => {
            return data;
        }));
    }


    private convertResponseToUser(responseUserObj: any): any {
        return responseUserObj;
    }

    public get getCurrentUser(): any {
        try {
            if (!localStorage.getItem('currentUser')) return {appVersion: environment.appVersion};
            return JSON.parse(this.decryptData());
        } catch (error) {
            localStorage.clear();
            window.location.reload();
            throw error;
        }
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    get isAdmin() {
        return this.currentUser && this.currentUserSubject.value.type === UserType.Admin;
    }

    public isUserAuthorizedForModule(entityName: string): boolean {
        let user = this.getCurrentUser;
        if (!user) return false;
        if (!(user.roles.data[entityName])) return user.type == UserType.Admin;
        return this.getCurrentUser.roles.data[entityName] != false || this.isAdmin;
    }

    public isUserAuthorizedForStream(entityName: string): boolean {
        let user = this.getCurrentUser;
        if (!user) return false;
        if (!(user?.roles?.data[entityName])) return user.type == UserType.Admin;
        return this.getCurrentUser.roles.data[entityName].stream == "yes" || this.isAdmin;
    }

    public isUserAuthorizedForAdd(entityName: string): boolean {
        let user = this.getCurrentUser;
        if (!user) return false;
        if (!(user?.roles?.data[entityName])) return user.type == UserType.Admin;
        return this.getCurrentUser.roles.data[entityName].create == "yes" || this.isAdmin;
    }


    public isUserAuthorizedForRead(entityName: string): boolean {
        let user = this.getCurrentUser;
        if (!user) return false;
        if (!(user?.roles?.data[entityName])) return user.type == UserType.Admin;
        return user.roles.data[entityName].read != "no" || user.type == UserType.Admin;
    }

    public isUserAuthorizedForEdit(entityName: string, assignedUserId?: any, teamsIds?: any): boolean {
        let user = this.getCurrentUser;
        if (!user) return false;
        if (!(user?.roles?.data[entityName])) return user.type == UserType.Admin;
        // eğer no değil ama own ise gelen id ile karşılaştır ve eşleşirse true döndür
        if (user.roles.data[entityName].edit == "own") {
            if(!assignedUserId) return false;
            return user.id == assignedUserId || user.type == UserType.Admin;
        }
        // eğer teamsIds varsa ve user.roles.data[entityName].edit == team ise, gelen teamsIds'in içindeki herhangi bir id user'da teamsIds içinde varsa true döndür
        if (teamsIds && user.roles.data[entityName].edit == "team") {
            return teamsIds.some((id: any) => user.teamsIds.includes(id)) || user.type == UserType.Admin;
        }
        return user.roles.data[entityName].edit != "no" || user.type == UserType.Admin;
    }

    public isUserAuthorizedForDelete(entityName: string, assignedUserId?:any, teamsIds?:any): boolean {
        let user = this.getCurrentUser;
        if (!user) return false;
        if (!(user?.roles?.data[entityName])) return user.type == UserType.Admin;
        // eğer no değil ama own ise gelen id ile karşılaştır ve eşleşirse true döndür
        if (user.roles.data[entityName].delete == "own") {
            if(!assignedUserId) return false;
            return user.id == assignedUserId || user.type == UserType.Admin;
        }
        // eğer teamsIds varsa ve user.roles.data[entityName].edit == team ise, gelen teamsIds'in içindeki herhangi bir id user'da teamsIds içinde varsa true döndür
        if (teamsIds && user.roles.data[entityName].delete == "team") {
            return teamsIds.some((teamId: any) => user.teamsIds.includes(teamId)) || user.type == UserType.Admin
        }
        return user.roles.data[entityName].delete != "no" || user.type == UserType.Admin;
    }

    public isUserAuthorizedFieldRead(entityName: any, field: any): boolean {
        let user = this.getCurrentUser;
        if (this.isAdmin) {
            return true;
        }
        if (!(user.fieldRole?.data)) {
            if (user.fieldRole?.data[entityName][field]?.read == 'yes') {
                return true;
            }
            return user.fieldRole?.data[entityName][field]?.read != 'no';
        } else {
            return true;
        }
    }

    public isUserAuthorizedFieldEdit(entityName: any, field: any): boolean {
        let user = this.getCurrentUser;
        if (this.isAdmin) {
            return true;
        }
        if (!(user.fieldRole?.data)) {
            if (user.fieldRole?.data[entityName][field]?.edit == 'yes') {
                return true;
            }
            return user.fieldRole?.data[entityName][field]?.edit != 'no';
        } else {
            return true;
        }
    }

    logout() {
        /*let remember = localStorage.getItem('remember');
        localStorage.clear();
        if (remember) {
            localStorage.setItem('remember', remember);
        }*/
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(undefined);
    }

    encryptData(data: string): string {
        return CryptoJS.AES.encrypt(data, this.secretKey).toString();
    }

    decryptData(data?: any): string {
        try {
            let encryptedData: any;
            if (!data) {
                encryptedData = localStorage.getItem('currentUser') || '';
            } else {
                encryptedData = data;
            }
            const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            localStorage.clear();
            window.location.reload();
            throw error;
        }
    }

    encryptLicenseCode(text: string): string {
        const iv = CryptoJS.lib.WordArray.random(16);
        const cipher = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(this.encSecretKey), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return iv.toString() + ':' + cipher.ciphertext.toString(CryptoJS.enc.Hex);
    }

    decryptLicenseCode(hash: string): any {
        try {
            const [ivHex, encryptedHex] = hash.split(':');
            const iv = CryptoJS.enc.Hex.parse(ivHex);
            const cipherText = CryptoJS.enc.Hex.parse(encryptedHex);

            // @ts-ignore
            const decrypted = CryptoJS.AES.decrypt({ciphertext: cipherText}, CryptoJS.enc.Utf8.parse(this.encSecretKey), {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            this.router.navigate(['/auth/login'])
        }
    }

    checkUserType() {
        let url = environment.apiUrl + '/checkUserType';
        let currentUser = this.getCurrentUser;
        return this.http.post(url, {
            userId: currentUser.id
        }).pipe(map((response: any) => {
            return response;
        }));
    }

    setCurrentUser(user: any) {
        localStorage.setItem('currentUser', this.encryptData(JSON.stringify(user)));
    }

}
