import {Inject, Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {BehaviorSubject, Subject} from "rxjs";
import {DataTransporter} from "../../models/dataTransporter";
import {AuthenticationService} from "../authentication/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    private readonly _orderByDesc: string = 'orderBy=createdAt&order=desc';
    public operationOccured = new Subject<any>();

    constructor(protected _http: HttpClient, @Inject(String) protected _entity: string,
                public authenticationService: AuthenticationService) {
    }

    getFullListByWhere(select?: any, where?: any, offset?: any, maxSize?: any, entity?: any, environments?: any) {
        let selectValue = !!select ? `select=${select}&` : '';
        let whereValue = !!where ? `${where}&` : '';
        let offsetValue = offset != undefined ? `offset=${offset}&maxSize=${maxSize || 200}&` : '';
        let environmentValue = environments || this.authenticationService.currentUserValue.url
        let url = `${environmentValue}/${this._entity}?${selectValue}${whereValue}&${this._orderByDesc}&${offsetValue}`;
        return this._http.get<any>(url).pipe(map((data: any) => {
            return data;
        }));
    }

    delete(id: any, environments?: any) {
        let environmentValue = environments || this.authenticationService.currentUserValue.url
        let url = `${environmentValue}/${this._entity}/${id}`;
        return this._http.delete(url);
    }

    deleteUserApi(id: any, environments?: any) {
        let environmentValue = environments || environment.baseApiUserUrl;
        let url = `${environmentValue}/${this._entity}/${id}`;
        return this._http.delete(url);
    }

    post(data: any, environments?: any) {
        let environmentValue = environments || this.authenticationService.currentUserValue.url
        let url = `${environmentValue}/${this._entity}`;
        return this._http.post<any>(url, data);
    }

    put(data: any, id: string, environments?: any) {
        let environmentValue = environments || this.authenticationService.currentUserValue.url
        let url = `${environmentValue}/${this._entity}/${id}`;
        return this._http.put<any>(url, data);
    }

    onDataChangeSubscriber() {
        return this.operationOccured.asObservable();
    }

    nextDataChange(value: any) {
        this.operationOccured.next(value);
    }

    duplicateData(entity: any, id: any) {
        let url = `${this.authenticationService.currentUserValue.url}/${entity}/action/getDuplicateAttributes`;
        return this._http.post<any>(url, {id: id}).pipe(map((data: any) => {
            return data;
        }));
    }
}
