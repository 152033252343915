<router-outlet></router-outlet>

<!-- Notification -->
<p-toast></p-toast>
<p-toast position="bottom-right" key="br"></p-toast>


<!--Progress Bar -->


<block-ui [template]="blockTemplate" >
</block-ui>

